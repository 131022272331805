var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addsuppliercontent" },
    [
      _c("div", { staticClass: "supplierbasic" }, [
        _c(
          "div",
          { staticClass: "comeback", staticStyle: { margin: "20px 0" } },
          [
            _c(
              "i",
              {
                staticClass: "el-icon-back",
                attrs: { title: "返回" },
                on: {
                  click: function($event) {
                    return _vm.cancelclick()
                  }
                }
              },
              [_vm._v("返回")]
            )
          ]
        ),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "supplierbasic_form" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  inline: true,
                  model: _vm.formInlinesupplier,
                  rules: _vm.rules
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商编号", prop: "customerCode" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "供应商编号" },
                      model: {
                        value: _vm.formInlinesupplier.customerCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "customerCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInlinesupplier.customerCode"
                      }
                    })
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "供应商系统编号" } }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formInlinesupplier.customerId))
                  ])
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "中文名称", prop: "customerNameCh" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "中文名称" },
                      model: {
                        value: _vm.formInlinesupplier.customerNameCh,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "customerNameCh",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInlinesupplier.customerNameCh"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "组织机构代码", prop: "organizationCode" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "组织机构代码" },
                      model: {
                        value: _vm.formInlinesupplier.organizationCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "organizationCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInlinesupplier.organizationCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "英文名称", prop: "customerNameEn" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "英文名称" },
                      model: {
                        value: _vm.formInlinesupplier.customerNameEn,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "customerNameEn",
                            $$v
                          )
                        },
                        expression: "formInlinesupplier.customerNameEn"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "税务登记证号",
                      prop: "taxRegistrationCode"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "税务登记证号" },
                      model: {
                        value: _vm.formInlinesupplier.taxRegistrationCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "taxRegistrationCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInlinesupplier.taxRegistrationCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "供应商状态" },
                        model: {
                          value: _vm.formInlinesupplier.customerStatus,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formInlinesupplier,
                              "customerStatus",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInlinesupplier.customerStatus"
                        }
                      },
                      _vm._l(this.supplierstatuslist, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { value: item.index, label: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "营业执照号码",
                      prop: "businessLicenseOfLegalEntity"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "营业执照号码" },
                      model: {
                        value:
                          _vm.formInlinesupplier.businessLicenseOfLegalEntity,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "businessLicenseOfLegalEntity",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "formInlinesupplier.businessLicenseOfLegalEntity"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商分类" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "供应商分类" },
                        model: {
                          value: _vm.formInlinesupplier.customerCategory,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formInlinesupplier,
                              "customerCategory",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInlinesupplier.customerCategory"
                        }
                      },
                      _vm._l(this.suppliertypelist, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { value: item.index, label: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "营业执照有效起期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "picker-options": _vm.pickerOptionsStart,
                        type: "date",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期"
                      },
                      model: {
                        value:
                          _vm.formInlinesupplier.startingDateOfBusinessLicens,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "startingDateOfBusinessLicens",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "formInlinesupplier.startingDateOfBusinessLicens"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "法定代表人", prop: "legalRepresentative" }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "法定代表人" },
                      model: {
                        value: _vm.formInlinesupplier.legalRepresentative,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "legalRepresentative",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInlinesupplier.legalRepresentative"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "营业执照有效止期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "picker-options": _vm.pickerOptionsEnd,
                        type: "date",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期"
                      },
                      model: {
                        value:
                          _vm.formInlinesupplier.expirationOfBusinessLicens,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "expirationOfBusinessLicens",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "formInlinesupplier.expirationOfBusinessLicens"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "统一社会信用代码",
                      prop: "unifiedSocialCreditCode"
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "统一社会信用代码" },
                      model: {
                        value: _vm.formInlinesupplier.unifiedSocialCreditCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "unifiedSocialCreditCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInlinesupplier.unifiedSocialCreditCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "企业成立日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期"
                      },
                      model: {
                        value: _vm.formInlinesupplier.dateOfEstablished,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formInlinesupplier,
                            "dateOfEstablished",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInlinesupplier.dateOfEstablished"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                staticStyle: { "text-align": "right", "margin-top": "15px" },
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      { name: "preventReClick", rawName: "v-preventReClick" }
                    ],
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.draftsave("ruleForm", "supplieradd")
                      }
                    }
                  },
                  [_vm._v("保存")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "linkmanmessage" }, [
        _c(
          "div",
          {
            staticClass: "supplierbasic_tit",
            staticStyle: { display: "flex" }
          },
          [
            _c("h2", { staticClass: "title" }, [_vm._v("联系人信息")]),
            _c(
              "div",
              {
                staticClass: "button",
                staticStyle: { flex: "1", "text-align": "right" }
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.linkmanadd()
                      }
                    }
                  },
                  [_vm._v("+新增联系人信息")]
                )
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "supplierbasic_table" }, [
          _c(
            "table",
            {
              staticClass: "contenttab",
              attrs: { cellpadding: "0", cellspacing: "0" }
            },
            [
              _vm._m(1),
              _c(
                "tbody",
                [
                  _vm._l(_vm.tableDatalinkman, function(item, index) {
                    return _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "80px",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            "white-space": "nowrap"
                          },
                          attrs: { title: item.uuid }
                        },
                        [_c("span", [_vm._v(_vm._s(item.uuid))])]
                      ),
                      _c("td", { attrs: { title: item.contactName } }, [
                        _c("span", [_vm._v(_vm._s(item.contactName))])
                      ]),
                      _c("td", { attrs: { title: item.contactTitle } }, [
                        _c("span", [_vm._v(_vm._s(item.contactTitle))])
                      ]),
                      _c("td", { attrs: { title: item.contactTypename } }, [
                        _c("span", [_vm._v(_vm._s(item.contactTypename))])
                      ]),
                      _c("td", { attrs: { title: item.mobilePhone } }, [
                        _c("span", [_vm._v(_vm._s(item.mobilePhone))])
                      ]),
                      _c("td", { attrs: { title: item.communicateNum } }, [
                        _c("span", [_vm._v(_vm._s(item.communicateNum))])
                      ]),
                      _c("td", { attrs: { title: item.officeTel } }, [
                        _c("span", [_vm._v(_vm._s(item.officeTel))])
                      ]),
                      _c("td", { attrs: { title: item.fax } }, [
                        _c("span", [_vm._v(_vm._s(item.fax))])
                      ]),
                      _c("td", { attrs: { title: item.email } }, [
                        _c("span", [_vm._v(_vm._s(item.email))])
                      ]),
                      _c("td", { attrs: { title: item.fixedLineTel } }, [
                        _c("span", [_vm._v(_vm._s(item.fixedLineTel))])
                      ]),
                      _c("td", { attrs: { title: item.addressUuid } }, [
                        _c("span", [_vm._v(_vm._s(item.addressUuid))])
                      ]),
                      _c("td", [
                        _c("i", {
                          staticClass: "el-icon-edit",
                          attrs: { type: "primary", title: "修改" },
                          on: {
                            click: function($event) {
                              return _vm.dictionarylinkmanupd(item)
                            }
                          }
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          attrs: { type: "primary", title: "删除" },
                          on: {
                            click: function($event) {
                              return _vm.dictionarylinkmandel(item)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  this.tableDatalinkman == "" ? _c("tr", [_vm._m(2)]) : _vm._e()
                ],
                2
              )
            ]
          )
        ]),
        this.iflinkman == true
          ? _c(
              "div",
              { staticClass: "supplierbasic_form linkman" },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleFormlinkman",
                    staticClass: "demo-form-inline",
                    attrs: {
                      inline: true,
                      model: _vm.formInlinelinkman,
                      rules: _vm.rules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系人类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "联系人类型" },
                            model: {
                              value: _vm.formInlinelinkman.contactType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formInlinelinkman,
                                  "contactType",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInlinelinkman.contactType"
                            }
                          },
                          _vm._l(this.linkmantypelist, function(item) {
                            return _c("el-option", {
                              key: item.index,
                              attrs: { value: item.index, label: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "办公电话", prop: "officeTel" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "办公电话" },
                          model: {
                            value: _vm.formInlinelinkman.officeTel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "officeTel",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.officeTel"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系人职位", prop: "contactTitle" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "联系人职位" },
                          model: {
                            value: _vm.formInlinelinkman.contactTitle,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "contactTitle",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.contactTitle"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "传真", prop: "fax" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "传真" },
                          model: {
                            value: _vm.formInlinelinkman.fax,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "fax",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.fax"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系人姓名", prop: "contactName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "联系人姓名" },
                          model: {
                            value: _vm.formInlinelinkman.contactName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "contactName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.contactName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "邮箱", prop: "email" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "邮箱" },
                          model: {
                            value: _vm.formInlinelinkman.email,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.email"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "移动电话", prop: "mobilePhone" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "移动电话" },
                          model: {
                            value: _vm.formInlinelinkman.mobilePhone,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "mobilePhone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.mobilePhone"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "固话电话", prop: "fixedLineTel" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "固话电话" },
                          model: {
                            value: _vm.formInlinelinkman.fixedLineTel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "fixedLineTel",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.fixedLineTel"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "社交软件号", prop: "communicateNum" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "社交软件号" },
                          model: {
                            value: _vm.formInlinelinkman.communicateNum,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlinelinkman,
                                "communicateNum",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlinelinkman.communicateNum"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "whole", attrs: { label: "地址信息" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: _vm.titlefalselinkman,
                              title: this.titlefalse,
                              placeholder: "址址信息"
                            },
                            model: {
                              value: _vm.formInlinelinkman.addressUuid,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formInlinelinkman,
                                  "addressUuid",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInlinelinkman.addressUuid"
                            }
                          },
                          _vm._l(this.addressmsgtypelist, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.uuid,
                                attrs: {
                                  label:
                                    (item.adddresname ? item.adddresname : "") +
                                    "：" +
                                    (item.detailAddress
                                      ? item.detailAddress
                                      : ""),
                                  value: item.uuid
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(
                                    _vm._s(
                                      item.adddresname ? item.adddresname : ""
                                    ) + "："
                                  )
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#8492a6",
                                      "font-size": "13px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.detailAddress
                                          ? item.detailAddress
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      "text-align": "right",
                      "margin-top": "15px"
                    },
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c("el-button", { on: { click: _vm.cancelinkman } }, [
                      _vm._v("取消")
                    ]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick"
                          }
                        ],
                        attrs: {
                          type: "primary",
                          disabled: _vm.ifformlinkman != true,
                          title: _vm.title
                        },
                        on: {
                          click: function($event) {
                            return _vm.draftsave("ruleFormlinkman", "linkman")
                          }
                        }
                      },
                      [_vm._v("保存")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "addressmessage" }, [
        _c(
          "div",
          {
            staticClass: "supplierbasic_tit",
            staticStyle: { display: "flex" }
          },
          [
            _c("h2", { staticClass: "title" }, [_vm._v("地址信息")]),
            _c(
              "div",
              {
                staticClass: "button",
                staticStyle: { flex: "1", "text-align": "right" }
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.addressadd()
                      }
                    }
                  },
                  [_vm._v("+新增地址信息")]
                )
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "supplierbasic_table" }, [
          _c(
            "table",
            {
              staticClass: "contenttab",
              attrs: { cellpadding: "0", cellspacing: "0" }
            },
            [
              _vm._m(3),
              _c(
                "tbody",
                [
                  _vm._l(_vm.addressmsgtypelist, function(item, index) {
                    return _c("tr", [
                      _c("td", { attrs: { title: item.uuid } }, [
                        _c("span", [_vm._v(_vm._s(item.uuid))])
                      ]),
                      _c("td", { attrs: { title: item.adddresname } }, [
                        _c("span", [_vm._v(_vm._s(item.adddresname))])
                      ]),
                      _c("td", { attrs: { title: item.countryCode } }, [
                        _c("span", [_vm._v(_vm._s(item.countryCode))])
                      ]),
                      _c("td", { attrs: { title: item.provinceCode } }, [
                        _c("span", [_vm._v(_vm._s(item.provinceCode))])
                      ]),
                      _c("td", { attrs: { title: item.cityCode } }, [
                        _c("span", [_vm._v(_vm._s(item.cityCode))])
                      ]),
                      _c("td", { attrs: { title: item.countyCode } }, [
                        _c("span", [_vm._v(_vm._s(item.countyCode))])
                      ]),
                      _c("td", { attrs: { title: item.postCode } }, [
                        _c("span", [_vm._v(_vm._s(item.postCode))])
                      ]),
                      _c("td", { attrs: { title: item.detailAddress } }, [
                        _c("span", [_vm._v(_vm._s(item.detailAddress))])
                      ]),
                      _c("td", [
                        _c("i", {
                          staticClass: "el-icon-edit",
                          attrs: { type: "primary", title: "修改" },
                          on: {
                            click: function($event) {
                              return _vm.dictionaryupd(item)
                            }
                          }
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          attrs: { type: "primary", title: "删除" },
                          on: {
                            click: function($event) {
                              return _vm.dictionarydel(item)
                            }
                          }
                        })
                      ])
                    ])
                  }),
                  this.addressmsgtypelist == ""
                    ? _c("tr", [_vm._m(4)])
                    : _vm._e()
                ],
                2
              )
            ]
          )
        ]),
        this.ifaddress == true
          ? _c(
              "div",
              { staticClass: "supplierbasic_form address" },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleFormaddress",
                    staticClass: "demo-form-inline",
                    attrs: {
                      inline: true,
                      model: _vm.formInlineaddress,
                      rules: _vm.rules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "地址类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "地址类型" },
                            model: {
                              value: _vm.formInlineaddress.addressType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formInlineaddress,
                                  "addressType",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInlineaddress.addressType"
                            }
                          },
                          _vm._l(this.addresstypelist, function(item) {
                            return _c("el-option", {
                              key: item.index,
                              attrs: { value: item.index, label: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "地址所在国家代码",
                          prop: "countryCode"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "地址所在国家代码" },
                            model: {
                              value: _vm.formInlineaddress.countryCode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formInlineaddress,
                                  "countryCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInlineaddress.countryCode"
                            }
                          },
                          _vm._l(this.addressstatetypelist, function(item) {
                            return _c("el-option", {
                              key: item.index,
                              attrs: { value: item.index, label: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "地址所在省代码", prop: "provinceCode" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "地址所在省代码" },
                          model: {
                            value: _vm.formInlineaddress.provinceCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlineaddress,
                                "provinceCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlineaddress.provinceCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "地址所在市代码" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "地址所在市代码" },
                          model: {
                            value: _vm.formInlineaddress.cityCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlineaddress,
                                "cityCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlineaddress.cityCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "地址所在县代码" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "地址所在县代码" },
                          model: {
                            value: _vm.formInlineaddress.countyCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlineaddress,
                                "countyCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlineaddress.countyCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "邮政编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "邮政编号" },
                          model: {
                            value: _vm.formInlineaddress.postCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlineaddress,
                                "postCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlineaddress.postCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "adr",
                        attrs: { label: "详细地址", prop: "detailAddress" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "详细地址" },
                          model: {
                            value: _vm.formInlineaddress.detailAddress,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInlineaddress,
                                "detailAddress",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInlineaddress.detailAddress"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      "text-align": "right",
                      "margin-top": "15px"
                    },
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c("el-button", { on: { click: _vm.canceaddress } }, [
                      _vm._v("取消")
                    ]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick"
                          }
                        ],
                        attrs: {
                          type: "primary",
                          disabled: _vm.ifformaddress != true
                        },
                        on: {
                          click: function($event) {
                            return _vm.draftsave("ruleFormaddress", "address")
                          }
                        }
                      },
                      [_vm._v("保存")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条" + _vm._s(this.deltag) + "数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "supplierbasic_tit" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("供应商基本信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("联系人编号")]),
      _c("th", [_vm._v("联系人姓名")]),
      _c("th", [_vm._v("联系人职位")]),
      _c("th", [_vm._v("联系人类型")]),
      _c("th", [_vm._v("移动电话")]),
      _c("th", [_vm._v("社交软件号")]),
      _c("th", [_vm._v("办公电话")]),
      _c("th", [_vm._v("传真")]),
      _c("th", [_vm._v("邮箱")]),
      _c("th", [_vm._v("固定电话")]),
      _c("th", [_vm._v("地址编号")]),
      _c("th", [_vm._v("操作")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "12" } }, [
      _c("p", { staticClass: "noDataClass" }, [_vm._v("暂无数据")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("地址编号")]),
      _c("th", [_vm._v("地址类型")]),
      _c("th", [_vm._v("地址所在国家代码")]),
      _c("th", [_vm._v("地址所在省代码")]),
      _c("th", [_vm._v("地址所在市代码")]),
      _c("th", [_vm._v("地址所在县代码")]),
      _c("th", [_vm._v("邮政编号")]),
      _c("th", [_vm._v("详细地址")]),
      _c("th", [_vm._v("操作")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "9" } }, [
      _c("p", { staticClass: "noDataClass" }, [_vm._v("暂无数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }