var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "showsuppliercontent" }, [
    _c("div", { staticClass: "supplierbasic" }, [
      _c(
        "div",
        { staticClass: "comeback", staticStyle: { margin: "20px 0" } },
        [
          _c(
            "i",
            {
              staticClass: "el-icon-back",
              attrs: { title: "返回" },
              on: {
                click: function($event) {
                  return _vm.back()
                }
              }
            },
            [_vm._v("返回")]
          )
        ]
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "supplierbasic_form" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.formInlinesupplierlist }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商编号", prop: "customerCode" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formInlinesupplierlist.customerCode))
                  ])
                ]
              ),
              _c("el-form-item", { attrs: { label: "供应商系统编号" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.formInlinesupplierlist.customerId))
                ])
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "中文名称", prop: "customerNameCh" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formInlinesupplierlist.customerNameCh))
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "组织机构代码", prop: "organizationCode" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formInlinesupplierlist.organizationCode))
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "英文名称", prop: "customerNameEn" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formInlinesupplierlist.customerNameEn))
                  ])
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "税务登记证号", prop: "taxRegistrationCode" }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formInlinesupplierlist.taxRegistrationCode)
                    )
                  ])
                ]
              ),
              _c("el-form-item", { attrs: { label: "供应商状态" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.formInlinesupplierlist.customerStatusname))
                ])
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "营业执照号码",
                    prop: "businessLicenseOfLegalEntity"
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.formInlinesupplierlist.businessLicenseOfLegalEntity
                      )
                    )
                  ])
                ]
              ),
              _c("el-form-item", { attrs: { label: "供应商分类" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.formInlinesupplierlist.customerCategoryname)
                  )
                ])
              ]),
              _c("el-form-item", { attrs: { label: "营业执照有效起期" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.formInlinesupplierlist.startingDateOfBusinessLicens
                    )
                  )
                ])
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "法定代表人", prop: "legalRepresentative" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formInlinesupplierlist.legalRepresentative)
                    )
                  ])
                ]
              ),
              _c("el-form-item", { attrs: { label: "营业执照有效止期" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.formInlinesupplierlist.expirationOfBusinessLicens
                    )
                  )
                ])
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "统一社会信用代码",
                    prop: "unifiedSocialCreditCode"
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formInlinesupplierlist.unifiedSocialCreditCode)
                    )
                  ])
                ]
              ),
              _c("el-form-item", { attrs: { label: "企业成立日期" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.formInlinesupplierlist.dateOfEstablished))
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "linkmanmessage" }, [
      _vm._m(1),
      _c("div", { staticClass: "supplierbasic_table" }, [
        _c(
          "table",
          {
            staticClass: "contenttab",
            attrs: { cellpadding: "0", cellspacing: "0" }
          },
          [
            _vm._m(2),
            _c(
              "tbody",
              [
                _vm._l(_vm.tableDatalinkman, function(item, index) {
                  return _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          width: "80px",
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap"
                        },
                        attrs: { title: item.uuid }
                      },
                      [_c("span", [_vm._v(_vm._s(item.uuid))])]
                    ),
                    _c("td", { attrs: { title: item.contactName } }, [
                      _c("span", { staticStyle: { width: "70px" } }, [
                        _vm._v(_vm._s(item.contactName))
                      ])
                    ]),
                    _c("td", { attrs: { title: item.contactTitle } }, [
                      _c("span", { staticStyle: { width: "70px" } }, [
                        _vm._v(_vm._s(item.contactTitle))
                      ])
                    ]),
                    _c("td", { attrs: { title: item.contactTypename } }, [
                      _c("span", { staticStyle: { width: "70px" } }, [
                        _vm._v(_vm._s(item.contactTypename))
                      ])
                    ]),
                    _c("td", { attrs: { title: item.mobilePhone } }, [
                      _c("span", [_vm._v(_vm._s(item.mobilePhone))])
                    ]),
                    _c("td", { attrs: { title: item.communicateNum } }, [
                      _c("span", [_vm._v(_vm._s(item.communicateNum))])
                    ]),
                    _c("td", { attrs: { title: item.officeTel } }, [
                      _c("span", [_vm._v(_vm._s(item.officeTel))])
                    ]),
                    _c("td", { attrs: { title: item.fax } }, [
                      _c("span", [_vm._v(_vm._s(item.fax))])
                    ]),
                    _c("td", { attrs: { title: item.email } }, [
                      _c("span", [_vm._v(_vm._s(item.email))])
                    ]),
                    _c("td", { attrs: { title: item.fixedLineTel } }, [
                      _c("span", [_vm._v(_vm._s(item.fixedLineTel))])
                    ]),
                    _c("td", { attrs: { title: item.addressUuid } }, [
                      _c("span", [_vm._v(_vm._s(item.addressUuid))])
                    ])
                  ])
                }),
                this.tableDatalinkman == "" ? _c("tr", [_vm._m(3)]) : _vm._e()
              ],
              2
            )
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "addressmessage" }, [
      _vm._m(4),
      _c("div", { staticClass: "supplierbasic_table" }, [
        _c(
          "table",
          {
            staticClass: "contenttab",
            attrs: { cellpadding: "0", cellspacing: "0" }
          },
          [
            _vm._m(5),
            _c(
              "tbody",
              [
                _vm._l(_vm.addressmsgtypelist, function(item, index) {
                  return _c("tr", [
                    _c("td", { attrs: { title: item.uuid } }, [
                      _c("span", [_vm._v(_vm._s(item.uuid))])
                    ]),
                    _c("td", { attrs: { title: item.adddresname } }, [
                      _c("span", [_vm._v(_vm._s(item.adddresname))])
                    ]),
                    _c("td", { attrs: { title: item.countryCode } }, [
                      _c("span", [_vm._v(_vm._s(item.countryCode))])
                    ]),
                    _c("td", { attrs: { title: item.provinceCode } }, [
                      _c("span", [_vm._v(_vm._s(item.provinceCode))])
                    ]),
                    _c("td", { attrs: { title: item.cityCode } }, [
                      _c("span", [_vm._v(_vm._s(item.cityCode))])
                    ]),
                    _c("td", { attrs: { title: item.countyCode } }, [
                      _c("span", [_vm._v(_vm._s(item.countyCode))])
                    ]),
                    _c("td", { attrs: { title: item.postCode } }, [
                      _c("span", [_vm._v(_vm._s(item.postCode))])
                    ]),
                    _c("td", { attrs: { title: item.detailAddress } }, [
                      _c("span", [_vm._v(_vm._s(item.detailAddress))])
                    ])
                  ])
                }),
                this.addressmsgtypelist == "" ? _c("tr", [_vm._m(6)]) : _vm._e()
              ],
              2
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "supplierbasic_tit",
        staticStyle: { "margin-top": "10px" }
      },
      [_c("h2", { staticClass: "title" }, [_vm._v("供应商基本信息")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "supplierbasic_tit", staticStyle: { display: "flex" } },
      [_c("h2", { staticClass: "title" }, [_vm._v("联系人信息")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("联系人编号")]),
      _c("th", [_vm._v("联系人姓名")]),
      _c("th", [_vm._v("联系人职位")]),
      _c("th", [_vm._v("联系人类型")]),
      _c("th", [_vm._v("移动电话")]),
      _c("th", [_vm._v("社交软件号")]),
      _c("th", [_vm._v("办公电话")]),
      _c("th", [_vm._v("传真")]),
      _c("th", [_vm._v("邮箱")]),
      _c("th", [_vm._v("固定电话")]),
      _c("th", [_vm._v("地址编号")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "11" } }, [
      _c("p", { staticClass: "noDataClass" }, [_vm._v("暂无数据")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "supplierbasic_tit", staticStyle: { display: "flex" } },
      [_c("h2", { staticClass: "title" }, [_vm._v("地址信息")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("地址编号")]),
      _c("th", [_vm._v("地址类型")]),
      _c("th", [_vm._v("地址所在国家代码")]),
      _c("th", [_vm._v("地址所在省代码")]),
      _c("th", [_vm._v("地址所在市代码")]),
      _c("th", [_vm._v("地址所在县代码")]),
      _c("th", [_vm._v("邮政编号")]),
      _c("th", [_vm._v("详细地址")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "8" } }, [
      _c("p", { staticClass: "noDataClass" }, [_vm._v("暂无数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }