var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "supplierrecord container" }, [
    _c(
      "div",
      { staticClass: "commoncontent" },
      [
        this.ifaddsure == 1
          ? _c("div", { attrs: { id: "" } }, [
              _c("div", { staticClass: "actions_part clearfix" }, [
                _c("div", { staticClass: "actions_wrap" }, [
                  _c(
                    "div",
                    { staticClass: "display_ib mr10 mb10" },
                    [
                      _c("span", { staticClass: "tag" }, [
                        _vm._v("供应商状态：")
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10",
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.supplierSearch },
                          model: {
                            value: _vm.customerStatus,
                            callback: function($$v) {
                              _vm.customerStatus = $$v
                            },
                            expression: "customerStatus"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "全部", value: "" }
                          }),
                          _vm._l(_vm.supplierstatuslist, function(item) {
                            return _c("el-option", {
                              key: item.index,
                              attrs: { label: item.name, value: item.index }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "display_ib mr10 mb10" },
                    [
                      _c("span", { staticClass: "tag" }, [
                        _vm._v("供应商分类：")
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10",
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.supplierSearch },
                          model: {
                            value: _vm.customerCategory,
                            callback: function($$v) {
                              _vm.customerCategory = $$v
                            },
                            expression: "customerCategory"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "全部", value: "" }
                          }),
                          _vm._l(_vm.suppliertypelist, function(item) {
                            return _c("el-option", {
                              key: item.index,
                              attrs: { label: item.name, value: item.index }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "display_ib mr10 mb10" },
                    [
                      _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10",
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.searchclick },
                          model: {
                            value: _vm.customerId,
                            callback: function($$v) {
                              _vm.customerId = $$v
                            },
                            expression: "customerId"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "全部", value: "" }
                          }),
                          _vm._l(_vm.supplierCodeList, function(item) {
                            return _c("el-option", {
                              key: item.supplierId,
                              attrs: {
                                label: item.supplierCode,
                                value: item.supplierId
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "actions_btn_wrap down t_right" },
                  [
                    _c("el-button", {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-search",
                        circle: "",
                        title: "搜索"
                      },
                      on: { click: _vm.searchclick }
                    }),
                    _c("el-button", {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-plus",
                        circle: "",
                        title: "新增供应商"
                      },
                      on: { click: _vm.requisitionadd }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticClass: "container_table clearfix mt20 table_part",
                  staticStyle: { "min-height": "536px" }
                },
                [
                  _c("singleTable", {
                    attrs: {
                      tableList: _vm.supplierList,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.fieldsList
                    },
                    on: {
                      deleteHandle: _vm.supplierdel,
                      editHandle: _vm.supplierview,
                      checkHandle: _vm.supplierview
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "paging",
                      staticStyle: { "margin-top": "30px", float: "right" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "block" },
                        [
                          _c("el-pagination", {
                            attrs: {
                              "current-page": _vm.currentPage,
                              "page-sizes": [10, 50],
                              "page-size": _vm.pageSize,
                              layout: "sizes,total, prev, pager, next",
                              total: this.allpage
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                              "update:currentPage": function($event) {
                                _vm.currentPage = $event
                              },
                              "update:current-page": function($event) {
                                _vm.currentPage = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        this.ifaddsure == 2
          ? _c(
              "div",
              { staticClass: "addsupplier" },
              [
                _c("addsuppliercontent", {
                  ref: "mychild",
                  attrs: {
                    formInlinesupplier: _vm.formInlinesupplier,
                    updatag: _vm.updatag,
                    tableDatalinkman: _vm.tableDatalinkman,
                    addressmsgtypelist: _vm.addressmsgtypelist
                  },
                  on: { close: _vm.close }
                })
              ],
              1
            )
          : _vm._e(),
        this.ifaddsure == 3
          ? _c(
              "div",
              { staticClass: "showsupplier" },
              [
                _c("showsuppliercontent", {
                  attrs: {
                    formInlinesupplierlist: _vm.formInlinesupplierlist,
                    tableDatalinkman: _vm.tableDatalinkman,
                    addressmsgtypelist: _vm.addressmsgtypelist
                  },
                  on: { close: _vm.close }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-dialog",
          {
            directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
            attrs: {
              title: "提示",
              visible: _vm.dialogdelVisible,
              "close-on-click-modal": false,
              width: "30%"
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogdelVisible = $event
              }
            }
          },
          [
            _c("span", [_vm._v("确定删除该条供应商数据?")]),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.dialogdelVisible = false
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      { name: "preventReClick", rawName: "v-preventReClick" }
                    ],
                    attrs: { type: "primary", disabled: _vm.isDisable },
                    on: {
                      click: function($event) {
                        return _vm.delVisibleclick()
                      }
                    }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }